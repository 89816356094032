
import { defineComponent, reactive, ref } from "vue";
import cardTool from "@/components/card-tool/card-tool.vue"

export default defineComponent({
  components: {
    cardTool
  },
  setup() {
    // 获取文章参数
    const articleParams = {
      query: "",
      pagenum: 1,
      pagesize: 9
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 9,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      console.log(page)
    }

    // 工具
    const tools = reactive([
      {
        title: "快手、抖音去水印",
        info: "输入快手、抖音视频链接去除水印",
        cover: require("../../assets/img/remove.webp"),
        link: "/tools/rm-watermark"
      },
      {
        title: "在线生成词云",
        info: "输入词云文字和上传词云形状图片，即可生成词云",
        cover: require("../../assets/img/banner03.png"),
        link: "/tools/ciyun"
      },
      {
        title: "VIP视频解析",
        info: "输入视频链接，开启一键解析，即可免费观看VIP视频",
        cover: require("../../assets/img/analysis-cover.jpg"),
        link: "/tools/analysis"
      }
    ])

    return {
      articleParams,
      tools,
      pagination,
      changePage
    }
  }

})
