
import { defineComponent } from "vue";

interface Tool {
  cover: string;
  title: string;
  info: string;
  link: string;
}

export default defineComponent({
  props: {
    tool: Object
  },
  setup() {
    return {}
  }
})
