<template>
  <div id="tools" class="center">
    <div class="main">
      <div class="title-wrapper">
        <div class="title">在线工具</div>
      </div>
      <ul>
        <li v-for="(item, index) in tools" :key="index">
          <card-tool :tool="item">
            <div class="obtain-btn">立即使用</div>
          </card-tool>
        </li>
      </ul>
      <div style="text-align: center;">
        <a-pagination 
          v-model:current="pagination.current" 
          :total="pagination.total" 
          v-model:pageSize="pagination.pagesize"
          show-less-items 
          @change="changePage"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import cardTool from "@/components/card-tool/card-tool.vue"

export default defineComponent({
  components: {
    cardTool
  },
  setup() {
    // 获取文章参数
    const articleParams = {
      query: "",
      pagenum: 1,
      pagesize: 9
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 9,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      console.log(page)
    }

    // 工具
    const tools = reactive([
      {
        title: "快手、抖音去水印",
        info: "输入快手、抖音视频链接去除水印",
        cover: require("../../assets/img/remove.webp"),
        link: "/tools/rm-watermark"
      },
      {
        title: "在线生成词云",
        info: "输入词云文字和上传词云形状图片，即可生成词云",
        cover: require("../../assets/img/banner03.png"),
        link: "/tools/ciyun"
      },
      {
        title: "VIP视频解析",
        info: "输入视频链接，开启一键解析，即可免费观看VIP视频",
        cover: require("../../assets/img/analysis-cover.jpg"),
        link: "/tools/analysis"
      }
    ])

    return {
      articleParams,
      tools,
      pagination,
      changePage
    }
  }

})
</script>

<style lang="scss" scoped>
  #tools {
    display: flex;
    justify-content: space-between;

    .main {
      width: 100%;
      background: white;
      padding: 20px;

      > ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 32%;
          margin-right: 2%;
          padding: 20px;
          border: 1px solid #eee;
          margin-top: 15px;
          transition: all .2s ease;

          .obtain-btn {
            display: block;
            line-height: 24px;
            height: 24px;
            text-align: center;
            font-size: 12px;
            width: 90px;
            color: #fff;
            cursor: pointer;
            background: linear-gradient(270deg,#2254f4,#406dff);
            box-shadow: 0 12px 30px 0 rgba(34,84,244,.2);
            border-radius: 12px;
          }

          &:hover {
            transform: translate3d(0, -7px, 0);
            box-shadow: 0 20px 60px rgba(61, 79, 127, 0.17);
            
            .title {
              color: #2254f4;
            }
          }
          
          &:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    #tools {

      .title-wrapper {
        margin-left: 10px;
      }

      .left {
        display: none;
      }

      .main {
        width: 100%;
        padding: 0px;
        padding-bottom: 20px;

        > ul {
          display: block;

          li {
            width: 100%;
            margin-right: 0;
            padding: 10px;
            border: none;
            border-bottom: 1px dashed #eee;
          }
        }
      }
    }
  }
</style>